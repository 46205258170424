

import { Options } from "vue-class-component";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import { Global } from "@/support/GlobalData";
import SessionBase from "./SessionBase";
import DeviceStatusModal from "./DeviceStatusModal.vue";
import App from "@/App.vue";
import StatusMessage from "./StatusMessage.vue";
import { DataRequest } from "@/shared/support/Data";
import { CameraImageDto } from "@/shared/models/CameraImageDto";
import { confirmOk, confirmYesWaitNo } from "@/shared/components/common/AlertDialog.vue";
import Utility from "@/shared/support/Utility";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { LightSelectionType, LightSelectionTypeDescription } from "@/shared/enums/LightSelectionType";
import SelectionSupport from "@/shared/support/Selection";
import ImageFooter from "./ImageFooter.vue";
import Globals from "@/support/Globals";
import SessionSidePanel from "./SessionSidePanel.vue";

@Options({
    components: {
        DeviceStatusModal,
        SessionSidePanel,
        StatusMessage,
        ImageFooter,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, re-initialize
                this.initialize();
            }
        }
    }
})
export default class CameraReady extends SessionBase {

    cameraAlignmentImage = "";
    lightsLoading = false;
    selectedLights = LightSelectionType.TargetScreen;

    lightSelections = SelectionSupport.EnumSelections(LightSelectionType, LightSelectionTypeDescription);

    resetData(): void {
        this.cameraAlignmentImage = "";
        this.lightsLoading = false;
        this.selectedLights = LightSelectionType.TargetScreen;
    }

    loadCameraAlignmentImage(): void {

        this.loading = true;

        const request = new DataRequest();
        request.$post<any, CameraImageDto>("/Service/CameraParser/GetCameraAlignmentImage", {
            CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid,
        })
            .then((cameraImage: CameraImageDto): void => {
                if (!cameraImage.Url) {
                    this.loading = false;
                    confirmOk("An unexpected error occurred retrieving the camera alignment image");
                    App.MainApp.routerReplace("/Connect");
                    return;
                }
                const url = Utility.formatUrl(cameraImage.Url, {}, Globals.GetWebApi(), true);
                this.cameraAlignmentImage = url;
            })
            .catch((reason): void => {
                this.loading = false;
                confirmOk(`An unexpected error occurred retrieving the camera alignment image - ${reason}`);
                App.MainApp.routerReplace("/Connect");
            });
    }
    cameraAlignmentImageLoaded(): void {
        this.loading = false;
    }
    cameraAlignmentImageError(): void {
        this.loading = false;
    }

    cameraKnownGoodImage(): string {
        if (!Global.Vehicle?.KnownGoodCameraUploadId) return "";
        return Utility.formatUrl(`/Service/Uploads/GetContentById/${Global.Vehicle.KnownGoodCameraUploadId}`, null, Globals.GetWebApi(), true);
    }

    lightChange(): void {
        this.lightsLoading = true;
        CalibrationEventSupport.sendEventLightIntensity(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.SetLightIntensity, Number(this.selectedLights));
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    startCameraAlignment(): void {
        this.loading = true;
        this.lightsLoading = true;
        CalibrationEventSupport.sendEvent(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.RequestLightIntensity);
        SignalR.onCameraAlignmentImageAvailable = (): void => {
            SignalR.onCameraAlignmentImageAvailable = null;
            this.loadCameraAlignmentImage();
        };
        CalibrationEventSupport.RetryableEvent((): void => {
            this.loading = true;
            CalibrationEventSupport.sendEvent(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.GetCameraAlignmentImage);
        }, (): Promise<ButtonClicked> => {
            return confirmYesWaitNo("The device did not respond. Do you want to retry receiving the camera image? Responding No will cancel the session.");
        }, (): void => {
            App.MainApp.routerReplace("/Connect");
        }, 60); // wait 60 seconds (slow internet)
    }
    sendCameraAlignmentRefresh(): void {
        this.startCameraAlignment();
    }
    sendCameraAlignmentVerify(): void {
        CalibrationEventSupport.RetryableEvent((): void => {
            this.loading = true;
            CalibrationEventSupport.sendEvent(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.CameraVerificationByTechnician);
            // If all goes well we will get the device status, which continues on the desired page.
        }, (): Promise<ButtonClicked> => {
            return confirmYesWaitNo("The device did not respond. Do you want to retry the camera verification? Responding No will cancel the session.");
        }, (): void => {
            App.MainApp.routerReplace("/Connect");
        }, 60); // wait 60 seconds (slow internet)
    }

    initialize(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        this.loading = true;
        this.$forceUpdate();
        SignalR.whenReady((): void => {
            this.pageLoading = false;
            this.$refs.refDeviceStatusModal.verifySessionFromContext(true, ():void => {
                this.resetData();
                this.startCameraAlignment();
                this.$nextTick((): void => {
                    this.$refs.refSessionSidePanel?.Update();
                });
            });
        });
    }
    mounted(): void {
        this.initialize();
        SignalR.onCurrentLightIntensity = (intensity: number): void => {
            this.selectedLights = intensity;
            this.lightsLoading = false;
        };
    }
    unmounted(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        SignalR.onCurrentLightIntensity = null;
    }

    $refs!: {
        refSessionSidePanel: SessionSidePanel,
        refDeviceStatusModal: DeviceStatusModal,
    }
}




import { UserRoles } from "@/identity";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import App from "@/App.vue";

export default class ImageVehicleButtonCell extends Vue {

    @Prop({ required: true }) readonly vehicleId: number = 0;

    canEdit(): boolean {
        return UserRoles.hasAny([ "AuggieVehicleTargetBrightnessEdit", "AuggieVehicleEdit" ]);
    }
    canView(): boolean {
        return !this.canEdit() && UserRoles.has("AuggieVehicleView");
    }

    showVehicleModal(): void {
        App.MainApp.routerPush("/Configuration", { vehicleid: this.vehicleId });
    }
}


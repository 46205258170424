

import { Options } from "vue-class-component";
import { DataRequest } from "@/shared/support/Data";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesWaitNo } from "@/shared/components/common/AlertDialog.vue";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import { CalibrationType, CalibrationTypeDescription, CalibrationTypeSpecific } from "@/shared/enums/CalibrationType";
import { SelectionEntry } from "@/shared/components/common/Selection.vue";
import SelectionSupport from "@/shared/support/Selection";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import { VehicleFilterType } from "@/shared/enums/VehicleFilterType";
import { Global } from "@/support/GlobalData";
import SessionBase from "./SessionBase";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import App from "@/App.vue";
import DeviceStatusModal from "./DeviceStatusModal.vue";
import BatteryPanel from "./BatteryPanel.vue";

@Options({
    components: {
        DeviceStatusModal,
        BatteryPanel,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, re-initialize
                if (!this.ignoreReload) this.initialize();
            }
        }
    }
})
export default class SessionVehicle extends SessionBase {

    selectedYear: number|string = "";
    yearSelections: any = null;
    selectedMake: string = "";
    makeSelections: any = null;
    selectedModel: string = "";
    modelSelections: any = null;
    selectedCalibration: CalibrationType|string = "";
    calibrationSelections: SelectionEntry[] = SelectionSupport.EnumSelections(CalibrationTypeSpecific, CalibrationTypeDescription);
    multipleCalibrations = false;
    vin: string = "";
    ignoreReload = false;

    get isValidVin(): boolean {
        if (this.vin.length === 0) return true;
        return this.vin.match(/^[0-9]{5}$/) != null;
    }

    resetData(): void {
        this.selectedYear = "";
        this.yearSelections = null;
        this.selectedMake = "";
        this.makeSelections = null;
        this.selectedModel = "";
        this.modelSelections = null;
        this.selectedCalibration = "";
        this.multipleCalibrations = false;
        this.vin = "";
        this.ignoreReload = false;
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    // Vehicle Selection

    makeChange():void {
        this.updateDropDownModel();
    }
    modelChange():void {
        this.updateDropDownYear();
    }
    yearChange():void {
        this.updateCalibration();
    }
    calibrationChange():void {
        // nothing to do
    }

    updateDropDownMake(): void {
        this.loading = true;

        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
            "VehicleFilterType": VehicleFilterType.VehicleMake,
            "CalibrationSessionGuid": Global.CalibrationSession!.CalibrationSessionGuid
        })
            .then((options:SelectionEntry[]): void => {
                this.makeSelections = options;
                this.selectedMake = "";
                this.modelSelections = [];
                this.selectedModel = "";
                this.yearSelections = [];
                this.selectedYear = "";
                this.selectedCalibration = "";
                this.multipleCalibrations = false;
            })
            .finally((): void => {
                this.loading = false;
            });
    }
    updateDropDownModel(): void {

        if (!this.selectedMake) {
            this.selectedModel = "";
            this.modelSelections = [];
            this.selectedYear = "";
            this.yearSelections = [];
            this.selectedCalibration = "";
            this.multipleCalibrations = false;
            return;
        }

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
            "VehicleFilterType": VehicleFilterType.VehicleModel,
            "VehicleMake": this.selectedMake,
            "CalibrationSessionGuid": Global.CalibrationSession!.CalibrationSessionGuid
        })
            .then((options:SelectionEntry[]): void => {
                this.modelSelections = options;
                this.selectedModel = "";
                this.yearSelections = [];
                this.selectedYear = "";
                this.selectedCalibration = "";
                this.multipleCalibrations = false;
            })
            .finally((): void => {
                this.loading = false;
            });
    }
    updateDropDownYear(): void {

        if (!this.selectedModel) {
            this.selectedYear = "";
            this.yearSelections = [];
            this.selectedCalibration = "";
            this.multipleCalibrations = false;
            return;
        }

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
            "VehicleFilterType": VehicleFilterType.VehicleYear,
            "VehicleMake": this.selectedMake,
            "VehicleModel": this.selectedModel,
            "CalibrationSessionGuid": Global.CalibrationSession!.CalibrationSessionGuid
        })
            .then((options:SelectionEntry[]): void => {
                this.yearSelections = options;
                this.selectedYear = "";
                this.selectedCalibration = "";
                this.multipleCalibrations = false;
            })
            .finally((): void => {
                this.loading = false;
            });
    }
    updateCalibration(): void {
        this.selectedCalibration = "";
        this.multipleCalibrations = false;
        if (!this.selectedYear)
            return;
        this.getCalibrationTypes();
    }

    getCalibrationTypes(): void {

        this.loading = true;

        const dr = new DataRequest();
        dr.autoToastOnFailure = false;
        dr.$get<VehicleDto[]>("/Service/Vehicle/All", {
            "VehicleYear": this.selectedYear,
            "VehicleMake": this.selectedMake,
            "VehicleModel": this.selectedModel,
            "CalibrationSessionGuid": Global.CalibrationSession!.CalibrationSessionGuid,
            "StatusFilter": GenericActiveStatus.Active,
        })
            .then((availableVehicles: VehicleDto[]): void => {
                this.loading = false;
                if (availableVehicles.length === 0) {
                    confirmOk(`No vehicle/target image information is available for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
                    return;
                }
                const vehicle = availableVehicles[0];
                const cals = vehicle.VehicleCalibrations.filter((v: VehicleCalibrationDto, index: number): boolean => {
                    return v.ActiveInd;
                });
                if (cals.length <= 0) {
                    confirmOk(`No vehicle calibration types are available for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
                } else if (cals.length === 1) {
                    this.multipleCalibrations = false;
                    this.selectedCalibration = cals[0].CalibrationType;
                } else { // cals.length > 1
                    this.multipleCalibrations = true;
                }
            })
            .catch((reason): void => {
                confirmOk(`Unable to retrieve calibration types for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
            })
            .finally((): void => {
                this.loading = false;
            });
    }

    sendVehicleClicked(): void {
        CalibrationEventSupport.RetryableEvent((): void => {
            this.loading = true;
            this.ignoreReload = true;
            CalibrationEventSupport.sendEventVehicleSelection(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.VehicleSelectionByTechnician,
                this.selectedYear as number, this.selectedMake, this.selectedModel, this.selectedCalibration as CalibrationType, this.vin);
            // If all goes well we will get the device status, which continues on the desired page.
        }, (): Promise<ButtonClicked> => {
            return confirmYesWaitNo("The device did not respond. Do you want to continue with the selected vehicle and retry? Responding No will cancel the session.");
        }, (): void => {
            App.MainApp.endCalibration();
        });
    }

    initialize(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        this.loading = true;
        SignalR.whenReady((): void => {
            this.pageLoading = false;
            this.$refs.refDeviceStatusModal.verifySessionFromContext(false, ():void => {
                this.resetData();
                this.loading = false;
                this.updateDropDownMake();
            });
        });
    }
    mounted(): void {
        this.initialize();
    }
    unmounted(): void {
        CalibrationEventSupport.CancelRetryableEvents();
    }

    $refs!: {
        refDeviceStatusModal: DeviceStatusModal,
    }
}


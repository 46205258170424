

import { Options, Vue } from "vue-class-component";
import BatteryPanel from "./BatteryPanel.vue";
import VehiclePanel from "./VehiclePanel.vue";
import WheelArchPanel from "./WheelArchPanel.vue";
import SessionNote from "./SessionNote.vue";
import SessionToolYpr from "./SessionToolYpr.vue";
import { UploadType } from "@/shared/enums/UploadType";
import UploadPanel from "@/shared/components/common/UploadPanel.vue";
import { Global } from "@/support/GlobalData";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";

@Options({
    components: {
        BatteryPanel,
        VehiclePanel,
        WheelArchPanel,
        SessionNote,
        SessionToolYpr,
    },
})
export default class SessionSidePanel extends Vue {

    vehicleId = "";

    getUploadType(): number {
        return UploadType.VehicleDocument;
    }
    getVehicle(): VehicleDto|null {
        return Global.Vehicle || null;
    }
    getCalibration(): VehicleCalibrationDto|null {
        return Global.VehicleCalibration || null;
    }
    getSession(): CalibrationSessionDto|null {
        return Global.CalibrationSession || null;
    }

    public Update(): void {
        this.vehicleId =  Global.VehicleCalibration?.VehicleId.toString() || "";
        this.$nextTick((): void => {
            this.$refs.refBatteryPanel?.Update();
            this.$refs.refVehiclePanel?.Update();
            this.$refs.refWheelArchPanel?.Update();
            this.$refs.refSessionNote?.Update();
            this.$refs.refSessionToolYpr?.Update();
        });
    }

    $refs!: {
        refBatteryPanel: typeof BatteryPanel,
        refVehiclePanel: VehiclePanel,
        refWheelArchPanel: WheelArchPanel,
        refSessionNote: SessionNote,
        refSessionToolYpr: SessionToolYpr,
        refAttachments: UploadPanel,
    }
}




import BatteryInfoDto from "@/shared/models/BatteryInfoDto";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import { defineComponent } from "vue";

export default defineComponent({

    data() {
        return {
            batteryLevel: null as number|null,
            charging: false,
            less20WasShown: false,
            less10WasShown: false,
        };
    },
    props: {
        showHeader: {type: Boolean, default: true },
    },
    methods: {
        getSession(): CalibrationSessionDto|null {
            return Global.CalibrationSession || null;
        },
        getStatusCss(): string {
            if (this.batteryLevel == null) return ""; // nothing to report
            if (this.showLess10() || this.showLess20()) return "";// don't over-colorize, we have alerts showing
            if (this.batteryLevel > 10 && this.batteryLevel <= 20) return "bg-warning";
            if (this.batteryLevel <= 10) return "bg-danger text-white";
            return "";
        },
        showLess20(): boolean {
            if (this.batteryLevel === null) return false;// nothing to report
            if (this.less20WasShown) return false;// already shown
            return this.batteryLevel > 10 && this.batteryLevel <= 20;
        },
        showLess10(): boolean {
            if (this.batteryLevel === null) return false;// nothing to report
            if (this.less10WasShown) return false;// already shown
            return this.batteryLevel <= 10;
        },
        close20Clicked(): void {
            BatteryPanelLess20WaShown = this.less20WasShown = true;
        },
        close10Clicked(): void {
            BatteryPanelLess10WaShown = this.less10WasShown = true;
        },
        Update(): void {
            // nothing to do, keep for consistency with other panels
        },
    },
    mounted(): void {
        SignalR.onBatteryInformation = (info: BatteryInfoDto): void => {
            this.batteryLevel = info.PercentageFull;
            this.charging = info.ExternalPowerInd;
        };
        SignalR.whenReady((): void => {
            const session = this.getSession();
            if (session) {
                if (session.BatteryPercentageFullEnd != null)
                    this.batteryLevel = session.BatteryPercentageFullEnd;
                else if (session.BatteryPercentageFullStart != null)
                    this.batteryLevel = session.BatteryPercentageFullStart;
                if (session.ExternalPowerEndInd != null)
                    this.charging = session.ExternalPowerEndInd;
                else if (session.ExternalPowerStartInd != null)
                    this.charging = session.ExternalPowerStartInd;
                if (session.CalibrationSessionGuid !== BatteryLevelSessionGuid) {
                    BatteryPanelLess20WaShown = false;
                    BatteryPanelLess10WaShown = false;
                    BatteryLevelSessionGuid = session.CalibrationSessionGuid;
                }
            }
            this.less20WasShown = BatteryPanelLess20WaShown;
            this.less10WasShown = BatteryPanelLess10WaShown;
        });
    },
    unmounted(): void {
        SignalR.onBatteryInformation = null;
    }
});

// Store what alerts were shown statically as the BatteryPanel is mounted/unmounted in the same session. By
// saving it statically we can remember across different instances of the BatteryPanel.
let BatteryLevelSessionGuid = "";
let BatteryPanelLess20WaShown = false;
let BatteryPanelLess10WaShown = false;




import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { DataRequest } from "@/shared/support/Data";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import Toast from "@/shared/support/Toast";
import Events from "@/shared/support/Events";
import { UserRoles } from "@/identity";
import Utility from "@/shared/support/Utility";
import TooltipSupport from "@/shared/support/Tooltip";
import App from "@/App.vue";
import Globals from "@/support/Globals";

export default class ImageButtonCell extends Vue {

    @Prop({ required: true }) readonly imageId: number = 0;
    @Prop({ required: true }) readonly imageName = "";
    @Prop({ required: true }) readonly uploadId: number = 0;
    @Prop({ required: true }) readonly vehicleCount: number = 0;
    @Prop({ required: true }) readonly active: boolean = false;

    canEdit(): boolean {
        return UserRoles.hasAny([ "AuggieVehicleTargetBrightnessEdit", "AuggieVehicleEdit" ]);
    }
    canView(): boolean {
        return !this.canEdit() && UserRoles.has("AuggieVehicleView");
    }
    canRemove(): boolean {
        return UserRoles.has("AuggieVehicleDelete");
    }

    showImageModal(): void {
        App.MainApp.routerPush("/Configuration", { imageid: this.imageId });
    }
    removeImage(): void {
        confirmYesNo(`Are you sure you want to deactivate Target Image "${this.imageName}"?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok)
                    this.postRemoveImage();
            });
    }
    restoreImage(): void {
        confirmYesNo(`Are you sure you want to restore Target Image "${this.imageName}"?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok)
                    this.postRestoreImage();
            });
    }
    private postRemoveImage(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/TargetImages/${this.imageId}`)
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Target Image "${this.imageName}" has been successfully deactivated.`);
                    Events.bubbleEvent(this, "image-grid-reload", this.imageId);
                } else {
                    confirmOk(`An error occurred removing Target Image "${this.imageName}".`);
                }
            }).catch((reason): void => {
                confirmOk(`An error occurred removing Target Image "${this.imageName}": ${reason}`);
            });
    }
    private postRestoreImage(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/TargetImages/${this.imageId}`, { Restore: true })
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Target Image "${this.imageName}" has been successfully restored.`);
                    Events.bubbleEvent(this, "image-grid-reload", this.imageId);
                } else {
                    confirmOk(`An error occurred restoring Target Image "${this.imageName}".`);
                }
            }).catch((reason): void => {
                confirmOk(`An error occurred restoring Target Image "${this.imageName}": ${reason}`);
            });
    }
    get downloadUrl(): string {
        return Utility.formatUrl(`/Service/Uploads/GetContentById/${this.uploadId}`, null, Globals.GetWebApi(), true);
    }
    clearTooltips(): void {
        TooltipSupport.clearTooltips();
    }
}

